import React from "react"
import Layout from "../components/Layout"
import indexStyle from "../scss/index.module.scss"
import { Link } from "gatsby"
import HeroTwo from "../components/HeroTwo"
import GalleryTwo from "../components/GalleryTwo"

const Services = () => {
  return (
    <Layout>
      <HeroTwo />
      <section className={indexStyle.content}>
        <div className={indexStyle.welcome}>
          <div className={indexStyle.innerWelcome}>
            <h3>Services</h3>
            <p>
              We offer a full range of painting and decorating solutions that
              are tailored to individual project requirements; whether you
              require a quote for your decorating project or would simply like
              some friendly advice, please get in touch, we would love to help!
            </p>

            <ul className={indexStyle.serviceslist}>
              <li>All internal and external/masonry paint work</li>
              <li>Residential and Commercial</li>
              <li>Wallpaper Hanging</li>
              <li>Eco friendly specialists</li>
              <li>Minimal disruption, we will work around you.</li>
              <li>Sash windows, Heritage Restoration</li>
            </ul>
            <div className={indexStyle.contact}>
              <Link to="/contact">Get In Touch</Link>
            </div>
          </div>
        </div>
      </section>
      <section className={indexStyle.gallery}>
        <GalleryTwo />
      </section>
    </Layout>
  )
}

export default Services
