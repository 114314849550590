import React from "react"
import galleryStyles from "./scss/gallery.module.scss"
import { graphql, useStaticQuery } from "gatsby"
import Img from "gatsby-image"

const GalleryTwo = () => {
  const images = useStaticQuery(graphql`
    query {
      before1: file(relativePath: { eq: "door1a.jpg" }) {
        childImageSharp {
          sizes(maxWidth: 500) {
            ...GatsbyImageSharpSizes
          }
        }
      }
      before2: file(relativePath: { eq: "door1b.jpg" }) {
        childImageSharp {
          sizes(maxWidth: 500) {
            ...GatsbyImageSharpSizes
          }
        }
      }
      before3: file(relativePath: { eq: "door1c.jpg" }) {
        childImageSharp {
          sizes(maxWidth: 500) {
            ...GatsbyImageSharpSizes
          }
        }
      }
      before4: file(relativePath: { eq: "door1d.jpg" }) {
        childImageSharp {
          sizes(maxWidth: 500) {
            ...GatsbyImageSharpSizes
          }
        }
      }
      before5: file(relativePath: { eq: "hall1.jpg" }) {
        childImageSharp {
          sizes(maxWidth: 500) {
            ...GatsbyImageSharpSizes
          }
        }
      }
      before6: file(relativePath: { eq: "hall2.jpg" }) {
        childImageSharp {
          sizes(maxWidth: 500) {
            ...GatsbyImageSharpSizes
          }
        }
      }
      before7: file(relativePath: { eq: "outside2a.jpg" }) {
        childImageSharp {
          sizes(maxWidth: 500) {
            ...GatsbyImageSharpSizes
          }
        }
      }
      before8: file(relativePath: { eq: "outside2d.jpg" }) {
        childImageSharp {
          sizes(maxWidth: 500) {
            ...GatsbyImageSharpSizes
          }
        }
      }
      before9: file(relativePath: { eq: "outside2e.jpg" }) {
        childImageSharp {
          sizes(maxWidth: 500) {
            ...GatsbyImageSharpSizes
          }
        }
      }
      before10: file(relativePath: { eq: "yellow1a.jpg" }) {
        childImageSharp {
          sizes(maxWidth: 500) {
            ...GatsbyImageSharpSizes
          }
        }
      }
      before11: file(relativePath: { eq: "yellow1b.jpg" }) {
        childImageSharp {
          sizes(maxWidth: 500) {
            ...GatsbyImageSharpSizes
          }
        }
      }
      before12: file(relativePath: { eq: "yellow1c.jpg" }) {
        childImageSharp {
          sizes(maxWidth: 500) {
            ...GatsbyImageSharpSizes
          }
        }
      }
      before13: file(relativePath: { eq: "fire1.jpg" }) {
        childImageSharp {
          sizes(maxWidth: 500) {
            ...GatsbyImageSharpSizes
          }
        }
      }
      before14: file(relativePath: { eq: "fire2.jpg" }) {
        childImageSharp {
          sizes(maxWidth: 500) {
            ...GatsbyImageSharpSizes
          }
        }
      }
      before15: file(relativePath: { eq: "fire3.jpg" }) {
        childImageSharp {
          sizes(maxWidth: 500) {
            ...GatsbyImageSharpSizes
          }
        }
      }
      before16: file(relativePath: { eq: "fire4.jpg" }) {
        childImageSharp {
          sizes(maxWidth: 500) {
            ...GatsbyImageSharpSizes
          }
        }
      }
    }
  `)

  return (
    <div className={galleryStyles.gallery}>
      <div className={`${galleryStyles.picture} ${galleryStyles.picOne}`}>
        <Img
          className={galleryStyles.image}
          sizes={images.before1.childImageSharp.sizes}
        />
      </div>
      <div className={`${galleryStyles.picture} ${galleryStyles.picOne}`}>
        <Img
          className={galleryStyles.image}
          sizes={images.before2.childImageSharp.sizes}
        />
      </div>
      <div className={`${galleryStyles.picture} ${galleryStyles.picOne}`}>
        <Img
          className={galleryStyles.image}
          sizes={images.before3.childImageSharp.sizes}
        />
      </div>
      <div className={`${galleryStyles.picture} ${galleryStyles.picOne}`}>
        <Img
          className={galleryStyles.image}
          sizes={images.before4.childImageSharp.sizes}
        />
      </div>
      <div className={`${galleryStyles.picture} ${galleryStyles.picOne}`}>
        <Img
          className={galleryStyles.image}
          sizes={images.before5.childImageSharp.sizes}
        />
      </div>
      <div className={`${galleryStyles.picture} ${galleryStyles.picOne}`}>
        <Img
          className={galleryStyles.image}
          sizes={images.before6.childImageSharp.sizes}
        />
      </div>
      <div className={`${galleryStyles.picture} ${galleryStyles.picOne}`}>
        <Img
          className={galleryStyles.image}
          sizes={images.before7.childImageSharp.sizes}
        />
      </div>
      <div className={`${galleryStyles.picture} ${galleryStyles.picOne}`}>
        <Img
          className={galleryStyles.image}
          sizes={images.before8.childImageSharp.sizes}
        />
      </div>
      <div className={`${galleryStyles.picture} ${galleryStyles.picOne}`}>
        <Img
          className={galleryStyles.image}
          sizes={images.before9.childImageSharp.sizes}
        />
      </div>
      <div className={`${galleryStyles.picture} ${galleryStyles.picOne}`}>
        <Img
          className={galleryStyles.image}
          sizes={images.before10.childImageSharp.sizes}
        />
      </div>
      <div className={`${galleryStyles.picture} ${galleryStyles.picOne}`}>
        <Img
          className={galleryStyles.image}
          sizes={images.before11.childImageSharp.sizes}
        />
      </div>
      <div className={`${galleryStyles.picture} ${galleryStyles.picOne}`}>
        <Img
          className={galleryStyles.image}
          sizes={images.before12.childImageSharp.sizes}
        />
      </div>
      <div className={`${galleryStyles.picture} ${galleryStyles.picOne}`}>
        <Img
          className={galleryStyles.image}
          sizes={images.before13.childImageSharp.sizes}
        />
      </div>
      <div className={`${galleryStyles.picture} ${galleryStyles.picOne}`}>
        <Img
          className={galleryStyles.image}
          sizes={images.before14.childImageSharp.sizes}
        />
      </div>
      <div className={`${galleryStyles.picture} ${galleryStyles.picOne}`}>
        <Img
          className={galleryStyles.image}
          sizes={images.before15.childImageSharp.sizes}
        />
      </div>
      <div className={`${galleryStyles.picture} ${galleryStyles.picOne}`}>
        <Img
          className={galleryStyles.image}
          sizes={images.before16.childImageSharp.sizes}
        />
      </div>
    </div>
  )
}

export default GalleryTwo
