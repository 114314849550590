import React from "react"
import { graphql, StaticQuery } from "gatsby"
import heroStyles from "./scss/hero.module.scss"
import BackgroundImage from "gatsby-background-image"
import styled from "styled-components"

const Hero = ({ className }) => (
  <StaticQuery
    query={graphql`
      query {
        images: file(relativePath: { eq: "living-room.jpg" }) {
          childImageSharp {
            fluid(quality: 100, maxWidth: 2500) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      }
    `}
    render={data => {
      const image = data.images.childImageSharp.fluid

      return (
        <BackgroundImage className={className} fluid={image}>
          <div className={heroStyles.hero}>
            <h2>Services</h2>
          </div>
        </BackgroundImage>
      )
    }}
  />
)

const BackgroundImageStyle = styled(Hero)`
  background-position: center;
`

export default BackgroundImageStyle
